export default {
  banana: {
    97: '0x6f82A9169d3b6DF8a88FD405bbd4722d97819fE9',
    56: '0xe7EAdA32CAF827d3bA8Cb1074830d803C9bD48c3',
    137: '0x5d47baba0d66083c52009271faf3f50dcc01023c',
  },
  syrup: {
    97: '0x786499d2A10C403C7F434D2f53366ce1fF6e6D47',
    56: '0x86Ef5e73EDB2Fea111909Fe35aFcC564572AcC06',
  },
  masterChef: {
    97: '0x238Fb8D7fa338bf35969DFedafC4d683186ef537',
    56: '0x21694642bea2D2E0B0f5129a25D753dd9fB9623A',
  },
  masterChefIDO: {
    97: '0x40C518fBBB3f74275c64524379c39C5A0E84BEc7',
  },
  sousChef: {
    97: '0x786499d2A10C403C7F434D2f53366ce1fF6e6D47',
    56: '0x54aff400858Dcac39797a81894D9920f16972D1D',
  },
  nativeWrapped: {
    97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  },
  lottery: {
    97: '0x8F277dEB2Bb279011F9F2AA4f25a717F65F8A1C5',
    // 97: '0xdf4F9CA6693e91D321280471a22774402b0b2131',
    56: '0xab9836Fd978E1B9fC1AD9d867AC441044F0AbE17',
  },
  lotteryNFT: {
    // 97: '0x94E9261D5b92496211E7F05EF458097a1903c899',
    // // '0x02A8F0b67aB46C5D8d7D79396f237E593628E261',
    // 56: '0x94E9261D5b92496211E7F05EF458097a1903c899',
    // // '0x42020f11483279353ae4cEb1c6aab6aE98ca7f50',
    97: '0xe6C9a8571591abc027c912A34984adCaAcB1A0C7',
    56: '0xe6C9a8571591abc027c912A34984adCaAcB1A0C7',
  },
  mulltiCall: {
    56: '0x38ce767d81de3940CFa5020B55af1A400ED4F657',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
    137: '0x95028E5B8a734bb7E2071F96De89BABe75be9C8E',
  },
  busd: {
    97: '0x98649fde88981790b574c9A6066004D5170Bf3EF',
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  },
  wbnb: {
    97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  },
  ust: {
    56: '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
    97: '',
  },
  sol: {
    56: '0x570A5D26f7765Ecb712C0924E4De545B89fD43dF',
  },

  matic: {
    56: '0xcc42724c6683b7e57334c4e856f4c9965ed682bd',
  },

  bananaProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0xb57ab339831cd5154ef2ed721ceba734aa9047bd',
  },
  nonFungibleApes: {
    56: '0x6afC012783e3a6eF8C5f05F8EeE2eDeF6a052Ec4',
    97: '0x34e9f595c4e00bf3b9149224e3109c9311267620',
  },
  rabbitMintingFarm: {
    56: '0x1C99222F857C1d72234703eC5b9Ed88089Bd7091',
    97: '0x8f4739a48883Fdd89b65DC245dD5774fC8f44a67',
  },
  eth: {
    56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    97: '0xd66c6b4f0be8ce5b39d52e0fd1344c389929b378',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  goldenBanana: {
    56: '0xdDb3Bd8645775F59496c821E4F55A7eA6A6dc299',
    97: '0x9407026d236DEAE22CC1F3c419A9e47CBfCFE9E5',
  },
  treasury: {
    56: '0xec4b9d1fd8A3534E31fcE1636c7479BcD29213aE',
    97: '0xbC5ed0829365a0d5bc3A4956A6A0549aE17f41Ab',
  },
  auction: {
    56: '0xaeCB396Be7F19618Db4C44d8e2E8C908228515E9',
    97: '0xe2782fb3f1c2506FA32484e728Bc42F578117dEC',
  },
  vaultApe: {
    56: '0x5711a833C943AD1e8312A9c7E5403d48c717e1aa',
    137: '0x37ac7DE40A6fd71FD1559Aa00F154E8dcb72efdb',
  },
  apePriceGetter: {
    56: '0xC8485D39FD1f5419c0029960112D04cfE0Ca8722',
    97: '0xD02Ab6967f22FCB5E86Ce52aCD484054aB646A58',
    137: '0x05D6C73D7de6E02B3f57677f849843c03320681c',
  },
  miniApeV2: {
    56: '',
    97: '',
    137: '0x54aff400858Dcac39797a81894D9920f16972D1D',
  },
  iazoFactoryProxy: {
    56: '0xD6C35D6551330a48Ed6d2e09b2BcBe38f6bA4C4a',
    97: '0x9739500a967fEAA2Ba373F8978085675eEF331a8',
  },
  iazoExposer: {
    56: '0xFdfb230bFa399EC32EA8e98c2E7E3CcD953C860A',
    97: '0xe977E40f29f699F75db2A137Af0B3Db2152404b6',
  },
  iazoSettings: {
    56: '0x624433b9C78dE84c8Dd3C9e906046017Bb03E3A6',
    97: '0x22FaB17bF074f07Ee2708868282897E619103369',
  },
  wineryToken: {
    56: '0x6f82A9169d3b6DF8a88FD405bbd4722d97819fE9',
    97: '0x6f82A9169d3b6DF8a88FD405bbd4722d97819fE9',
  },
  nftLaunchpad: {
    97: '0xE9Ab9Be18001A04997404E9A6fad054475E156D9',
  },
  smartChefNft: {
    56: '0x191299E9dDC466CFc54e636f6f11e10080E34908',
    97: '0x191299E9dDC466CFc54e636f6f11e10080E34908',
  },
  nft: {
    56: '0x69BF66f61Ff4FF75F9565A62b9bC2521453c1680',
    97: '0x45F3Fb50a69B106ecC071e081fd07E39498A78Af',
  },
  marketNft: {
    56: '0x2937d05C80354BF29C793D115007E52EB61b817b',
    97: '0xF975cFF494eF91039E186c0a7017baD119B6cc92',
  },
  auctionNft: {
    56: '0xa83ecb79FD0Ca92eDf1d380339CACB2d0fcC8218',
    97: '0xc247991A0f2D442312Bf78e7cF1c366480473fe3',
  },
  referral: {
    97: '0xDa96165fD959AE6A7B50dab3A4AaB5E5Bf6B13E0',
    56: '0x0f60c50bbb6a9559d877b8ff66def0255fb14110',
  },
  galleryLaunchpad: {
    56: '0x6B2c06BDf73a82c80244b4C5c4B5F8ac0571f2B9',
    97: '0x3c5aE355AF8389b481C12fd8799bf1EAC6c6516E',
  },
  galleryNft: {
    56: '0x63C35Bdd964B405Dc7f283288F49064b6e29fA72',
    97: '0xd6DAB0b18597B7c2EB47e4C766aa1E8247508DB1',
  },
  airdrop: {
    97: '0xcA4D67f3Edf52C2474329D35CA054DB35eeB88e7',
    56: '0xa8527041c437009415691a03d69e413d2b528fc7',
  },
}
