import { Card, CloseIcon, useModal } from '@apeswapfinance/uikit'
import React from 'react'
import styled from 'styled-components'

const StyledCard = styled(Card)`
  padding: 2rem;

  position: relative;
  z-index: 100;
  max-width: 90%;
  width: 1000px;
  background: transparent;
  /* min-height: 350px; */
  /* border: 1px solid ${({ theme }) => theme.colors.primary}; */
`

const StyledCloseIcon = styled(CloseIcon)<{ width?: string }>`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;

  transition: 0.2s ease-out;
  fill: #fff;
  ${({ width }) => width && `width:${width};`}
  :hover {
    fill: red;
    opacity: 0.8;
  }
`

const StyledImg = styled.img<{ width?: string }>`
  width: ${({ width }) => width ?? '100%'};
  height: 80%;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.primary};
`

const ImgDesktop = styled.img`
  display: none;
  width: 100%;
  ${({ theme }) => theme.mediaQueries.md} {
    display:block
  }
`
const ImgMobile = styled.img`
  display: block;
  width: 100%;
  ${({ theme }) => theme.mediaQueries.md} {
    display: none;
  }
`

const PopupPageModal: React.FC<{ onDismiss?: () => void }> = ({ onDismiss }) => {
  return (
    <StyledCard
      as={'a'}
      href="https://poolswinery.it/market"
      // target="_blank"
      // rel="noopener noreferrer"
      style={{ padding: 0, background: 'transaparent' }}
    >
      <ImgDesktop src="/popup_desktop.png" alt="poup" />
      <ImgMobile src="/popup_mobile.png" alt="poup" />
      <StyledCloseIcon
        width="30px"
        onClick={(e) => {
          onDismiss()

          e.preventDefault()
        }}
      />
    </StyledCard>
  )
}

export default { PopupPageModal }
