// Network chain ids

export const CHAIN_ID = {
  BSC: 56,
  BSC_TESTNET: 97,
  ETHEREUM: 1,
  RINKEBY: 4,
  MATIC: 137,
  MATIC_TESTNET: 80001,
}

// Network labels

export const NETWORK_LABEL = {
  [CHAIN_ID.BSC]: 'BSC',
  [CHAIN_ID.BSC_TESTNET]: 'BSC Testnet',
  [CHAIN_ID.ETHEREUM]: 'Ethereum',
  [CHAIN_ID.RINKEBY]: 'Rinkeby Testnet',
  [CHAIN_ID.MATIC]: 'Polygon',
  [CHAIN_ID.MATIC_TESTNET]: 'Polygon Testnet',
}

// Network icons

export const NETWORK_ICON = {
  [CHAIN_ID.BSC]: '',
  [CHAIN_ID.BSC_TESTNET]: '',
  [CHAIN_ID.ETHEREUM]: '',
  [CHAIN_ID.RINKEBY]: '',
  [CHAIN_ID.MATIC]: '',
  [CHAIN_ID.MATIC_TESTNET]: '',
}

export const NETWORK_INFO_LINK = {
  [CHAIN_ID.BSC]: 'https://info.apeswap.finance',
  [CHAIN_ID.BSC_TESTNET]: 'https://info.apeswap.finance',
  [CHAIN_ID.MATIC]: 'https://polygon.info.apeswap.finance/',
  [CHAIN_ID.MATIC_TESTNET]: 'https://polygon.info.apeswap.finance/',
}

// Network RPC nodes
export const NETWORK_RPC = {
  [CHAIN_ID.BSC]: [
    'https://bsc-dataseed3.defibit.io',
    'https://bsc-dataseed2.binance.org',
    'https://bsc-dataseed4.binance.org',
  ],
  [CHAIN_ID.BSC_TESTNET]: [
    // 'https://speedy-nodes-nyc.moralis.io/c5b198b59840439a793a82ea/bsc/testnet'
    'https://bsc-testnet.nodereal.io/v1/5cd049a82f194ba4b06adf80199ea40d',
    // 'https://bsc-testnet.nodereal.io/v1/1a4f350f18974d6e88d0c494cd5b4ff5',
  ],
  [CHAIN_ID.ETHEREUM]: '',
  [CHAIN_ID.RINKEBY]: '',
  [CHAIN_ID.MATIC]: ['https://polygon-rpc.com/'],
  [CHAIN_ID.MATIC_TESTNET]: ['https://matic-mumbai.chainstacklabs.com'],
}

// Network block explorers

export const BLOCK_EXPLORER = {
  [CHAIN_ID.BSC]: 'https://bscscan.com',
  [CHAIN_ID.BSC_TESTNET]: 'https://testnet.bscscan.com/',
  [CHAIN_ID.MATIC]: 'https://polygonscan.com',
  [CHAIN_ID.MATIC_TESTNET]: 'https://mumbai.polygonscan.com/',
}

export const CHAIN_PARAMS = {
  [CHAIN_ID.BSC]: {
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'bnb',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: NETWORK_RPC[CHAIN_ID.BSC],
    blockExplorerUrls: [BLOCK_EXPLORER[CHAIN_ID.BSC]],
  },
  [CHAIN_ID.BSC_TESTNET]: {
    chainId: '0x61',
    chainName: 'Binance Smart Chain Testnet',
    nativeCurrency: {
      name: 'bnb',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: NETWORK_RPC[CHAIN_ID.BSC_TESTNET],
    blockExplorerUrls: [BLOCK_EXPLORER[CHAIN_ID.BSC_TESTNET]],
  },
  [CHAIN_ID.MATIC]: {
    chainId: '0x89',
    chainName: 'Matic',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: NETWORK_RPC[CHAIN_ID.MATIC],
    blockExplorerUrls: [BLOCK_EXPLORER[CHAIN_ID.MATIC]],
  },
  [CHAIN_ID.MATIC_TESTNET]: {
    chainId: '0x89',
    chainName: 'Matic',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: NETWORK_RPC[CHAIN_ID.MATIC_TESTNET],
    blockExplorerUrls: [BLOCK_EXPLORER[CHAIN_ID.MATIC_TESTNET]],
  },
}
